import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Appointment from '../components/Appointment/appointment'

export default function BookAppointment() {
    return (
        <Layout>
            <Seo title="Book Your Appointment" />
            <Appointment />
        </Layout>
    )
}
